import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Text from '../components/Core/Text'
import Hero from '../components/Shared/Hero'
import Blog from '../components/Shared/Blog'
import * as styles from './styles/blog.module.scss'
import { graphql } from 'gatsby'
import Push from '../components/Shared/Push'
import useDate from '../hooks/useDate'
import { replaceWithBr, stripeHtmlTag } from '../utils/mixins'
import useEllipsis from '../hooks/useEllipsis'
import DecoBigIllus from '../components/Illus/DecoBigIllus'
import { navigate } from '../../plugins/gatsby-plugin-intl-kley'
import { useTranslation } from '../hooks/useTranslation'
import replaceHife from '../utils/replaceHife'
import Breadcrumb from '../components/Core/Breadcrumb'
import { head } from '../components/head'
import usePageView from '../hooks/usePageView'
import useLinkLocalized from '../hooks/useLinkLocalized'
import slugify from 'slugify'

interface RelatedArticles {
  name: string
  introduction: string
  title: string
  shortTitle: string
  articleBody: string
  category: {
    title: string
  }
  thumbnail: any
  slug: any
}

export const Head = head('umbracoBlogPost')

export default function BlogDetail({ data, location }: any) {
  const { t } = useTranslation('blog')
  const blog = data.umbracoBlogPost
  const blogPage = data.umbracoBlogPage
  const header = data.umbracoHeader
  const pageTitle = blog.metaTitle
    ? blog.metaTitle
    : blog.title
    ? blog.titlethumbnail
    : 'Blog post'

  const popinGeneralVisible = blog?.popinGeneralVisible

  const date = useDate(blog._createDate)
  const ellipsis = useEllipsis()

  const handleGoTo = () => {
    ;(window as any).category = blog.category.title
    navigate(blogPage._url)
  }

  useEffect(() => {
    window.scrollTo(0, 0)

    if (typeof window !== 'undefined' && window.document) {
      usePageView(pageTitle, window.location.pathname)
    }
  }, [])

  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BlogPosting',
            headline: blog.title,
            image: blog.imageCover
              ? blog.imageCover.childImageSharp.gatsbyImageData.images.fallback
                  .src
              : '',
            description: stripeHtmlTag(ellipsis(blog.articleBody, 250)),
            author: {
              '@type': 'Organization',
              name: 'Kley Urban Village',
            },
            publisher: {
              '@type': 'Organization',
              name: 'Kley Urban Village',
              logo: {
                '@type': 'ImageObject',
                url: header?.logo?.src,
              },
            },
            datePublished: blog._createDate,
            dateModified: blog._updateDate,
          }),
        }}
      ></script>
      <Layout canDisplayPopin={popinGeneralVisible}>
        <Hero
          title={blog.title}
          image={blog.imageCover}
          image-mobile={blog.imageCoverMobile}
          video={blog.videoCover}
          showTitle={false}
        >
          <DecoBigIllus className="deco" />
        </Hero>
        <section className={styles.blogDetail}>
          <div className="container">
            <div className={styles.blogInfo}>
              <Text
                tag="p"
                as="captionH1"
                className="font-bold cursor-pointer"
                style={{ color: `#${blog.category.color}` }}
                onClick={handleGoTo}
              >
                {blog.category.title}
              </Text>
              <Text tag="p" as="subtitleH4" style={{ color: `#6C7474` }}>
                {date}
              </Text>
            </div>
            <Text tag="h1" as="h2">
              {blog.title}
            </Text>
            <Text
              tag="div"
              as="subtitleH1"
              dangerouslySetInnerHTML={{ __html: replaceHife(blog.chapeau) }}
            />

            <div
              className={styles.blogContent}
              dangerouslySetInnerHTML={{
                __html: replaceHife(replaceWithBr(blog.articleBody, true)),
              }}
            />

            <div className={styles.blogShare}>
              <Text tag="p" as="navH1" className="font-bold">
                {t('Partager', 'blog')}
              </Text>

              <div className="footer__social-list mt-3">
                <a
                  href={`http://www.facebook.com/share.php?u=${location.href}`}
                  rel="noopened noreferrer"
                  target="_blank"
                  className="text-white cursor-pointer social-item hover:opacity-50"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.375 9.6875C19.375 4.33594 15.0391 0 9.6875 0C4.33594 0 0 4.33594 0 9.6875C0 14.5227 3.54258 18.5305 8.17383 19.2578V12.4879H5.71289V9.6875H8.17383V7.55312C8.17383 5.12539 9.61914 3.78437 11.8328 3.78437C12.893 3.78437 14.0016 3.97344 14.0016 3.97344V6.35625H12.7797C11.5766 6.35625 11.2012 7.10312 11.2012 7.86914V9.6875H13.8879L13.4582 12.4879H11.2012V19.2578C15.8324 18.5305 19.375 14.5227 19.375 9.6875Z"
                      fill="#142336"
                    />
                  </svg>
                </a>
                <a
                  href={`https://twitter.com/intent/tweet?text=${blog.title}&url=${location.href}`}
                  rel="noopened noreferrer"
                  target="_blank"
                  className="text-white cursor-pointer social-item hover:opacity-50 pl-2"
                >
                  <svg
                    width="21"
                    height="16"
                    viewBox="0 0 21 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.4569 3.98749C18.47 4.16248 18.47 4.33751 18.47 4.5125C18.47 9.84997 14.2278 16 6.47428 16C4.08557 16 1.86658 15.3375 0 14.1875C0.33939 14.225 0.665681 14.2375 1.01813 14.2375C2.9891 14.2375 4.80349 13.6 6.25237 12.5125C4.39885 12.475 2.84554 11.3125 2.31036 9.7125C2.57144 9.74997 2.83248 9.77498 3.10662 9.77498C3.48514 9.77498 3.86371 9.72496 4.21611 9.63751C2.28428 9.26248 0.835356 7.63749 0.835356 5.67499V5.62501C1.39661 5.92501 2.04932 6.11251 2.74107 6.13748C1.60546 5.41247 0.861472 4.17498 0.861472 2.77497C0.861472 2.02499 1.07028 1.33749 1.43579 0.737481C3.51122 3.18748 6.6309 4.78745 10.1291 4.96248C10.0638 4.66248 10.0246 4.35001 10.0246 4.03751C10.0246 1.81248 11.9043 0 14.2407 0C15.4547 0 16.5511 0.487499 17.3213 1.275C18.2741 1.10001 19.1878 0.762491 19.9971 0.300002C19.6838 1.23752 19.0181 2.02502 18.1436 2.52499C18.992 2.43753 19.8144 2.21248 20.5714 1.90001C19.9972 2.69998 19.2792 3.41245 18.4569 3.98749Z"
                      fill="#142336"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </section>

        {
          // TODO: get random article
          blog.relatedArticles && (
            <section className={`container ${styles.blogOther}`}>
              <Text tag="div" as="h2" className="uppercase font-extrabold h2">
                {t('Autres actualités', 'blog')}
              </Text>
              <Blog
                data={blog.relatedArticles?.map((b: RelatedArticles) => ({
                  title: b.title,
                  shortTitle: b.shortTitle,
                  category: b.category.title,
                  name: b.name,
                  _url:
                    blogPage._url +
                     (slugify(b.name, { lower: true, remove: /[:]/g })).replace(/['"]/g, '-').replace(/\W*$/, ""),
                  content: ellipsis(
                    b.introduction || stripeHtmlTag(b.articleBody),
                    123
                  ),
                  thumbnail: b.thumbnail?.media
                    ? b.thumbnail.media?._url
                    : b.thumbnail
                    ? b.thumbnail
                    : '',
                }))}
              />
            </section>
          )
        }
        <Push data={{ itemsPush: blog.blockPush }} />

        <Breadcrumb
          data={[
            {
              title: blogPage.title,
              link: useLinkLocalized({ url: blogPage._url }),
              id: '' + useLinkLocalized({ url: blogPage._url }),
            },
            {
              title: blog.title,
              link: location.pathname,
              id: '' + location.pathname,
            },
          ]}
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($name: String!, $lang: String) {
    umbracoBlogPage(lang: { eq: $lang }) {
      _url
      title
    }
    umbracoBlogPost(name: { eq: $name }, lang: { eq: $lang }) {
      name
      metaTitle
      metaDescription
      metaFollow
      metaIndex
      twitterCard {
        id
        url
      }
      facebookCard {
        id
        url
      }
      _url
      _urls {
        fr
        en_us
      }
      title
      chapeau
      imageCover {
        childImageSharp {
          id
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      imageCoverMobile {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      videoCover {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      popinGeneralVisible
      articleBody
      _createDate
      _updateDate
      shortTitle
      category {
        name
        title
        color
      }
      blockPush {
        name
        title
        backgroundColor
        title
        subtitle
        link {
          url
          type
          name
        }
        illustration {
          src
          gatsbyImageData(width: 400, quality: 90)
        }
      }
      relatedArticles {
        _id
        name
        introduction
        title
        shortTitle
        articleBody
        slug
        category {
          color
          title
        }
        thumbnail {
          media {
            mediaTypeAlias
            _url
            name
            _id
            alt
          }
        }
      }
    }
    umbracoHeader {
      logo {
        src
      }
    }
    allPageIntl {
      edges {
        node {
          name
          uri
          alternates {
            name
            uri
            lang
          }
        }
      }
    }
  }
`
